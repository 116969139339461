.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.logo {
  margin-bottom: 1rem;
}
.icts {
  width: 30%;
  margin-top: 1rem;
}

.names {
  font-size: 18px;
  text-align: center;
}
