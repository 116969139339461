@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* Below animations are for modal created using React-Modal */

*:focus, *:focus-visible{
    outline: none;
}
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}


h1, h2{
    font-family: 'Raleway';
    
}
p{
    font-family: 'Lato';
}
.logo {
  transform: scale(0.5);
}

.anim {
  opacity: 0.7;
  transition: 0.15s ease-in-out;
}
.anim:hover {
  opacity: 1;
}

.navs {
  font-weight: 500;
  font-size: 17px;
  margin-right: 1rem;
  transition: 0.2s ease-in-out;
}
.navs:hover {
  font-weight: 600;
}
.loader-div {
  transition: 0.1s ease-in-out;
}

.carousel-container {
  width: 80%;
  margin: 10rem auto;
  /* height:600px; */
  height: 400px;
  margin-top: 10px;
  /* margin-bottom: 200px; */
}

.slick-slider {
  margin-top: 10px;
}
.slick-slide {
  height: 600px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.Slider {
  height: 1800px;
}

.slide img {
  width: 20rem;
  margin: 0 auto;
}

.slide {
  transform: scale(0.38);
  transition: transform 300ms;
  opacity: 0.5;
  pointer-events: none;
}
.slide .desc {
  visibility: hidden;
}

.activeSlide {
  cursor: pointer;
  transform: scale(0.75);
  opacity: 1;
  transition: h3 5s ease-in 0.7s;
  pointer-events: unset;
}

.normal {
  /* transform: scale(0.38); */
}
.abnormal {
  transform: scale(1.1);
}

.normal {
  display: inline-block;
  /* background-color: #3a5173; */
  transform: translateY(150px);
}
.abnormal {
  display: inline-block;
  /* background-color: #3a5173; */
  transform: translateY(150px);
}

.activeSlide .desc {
  visibility: visible;
  transform: scale(1);
  text-align: center;
}

div.desc {
  /* background-color: #f0fff4; */
  margin-top: 150px;
  border-radius: 3px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
/* p.bash-description{
    width: "400px";
    font-size: " 20px";
    font-weight: "400",
  } */

/* p.desc{
    text-align: center;
  } */

p.click-notify {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5px;
  font-size: 10px;
  font-weight: medium;
  color: #cbd5e0;
}
.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
  color: #718096;
}

.arrow svg:hover {
  color: #3a5173;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

/* .gif_player {
    margin: 1rem;
  } */

/* .gif_player img {
      transform: scale(1);
    } */

/* div.abnormal{
    height: 70px;
  } */

/* .abnormal .texts{
    transform: translateX(300px)
  } */

/* mobile */
@media only screen and (max-width: 768px) {
  h3.bash-name {
    /* font-size: clamp(0.5rem, -0.875rem + 6.333333vw, 1rem); */
    text-align: center;
    font-size: 12px;
    font-weight: 600;
  }
  p.bash-description {
    /* font-size: clamp(0.5rem, -0.875rem + 6.333333vw, 1rem); */
    text-align: center;
    font-size: 9px;
  }
  p.click-notify {
    text-align: center;
    font-size: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .carousel-container {
    width: 80%;
    margin: 10rem auto;
    height: 300px;
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .slick-slider {
    margin-top: 10px;
  }
  .slick-slide {
    height: 400px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  Slider {
    height: 1800px;
  }

  .slide img {
    width: 20rem;
    margin: 0 auto;
  }

  .slide {
    transform: scale(0.2);
    transition: transform 300ms;
    opacity: 0.5;
    pointer-events: none;
  }
  .slide .desc {
    visibility: hidden;
  }

  .activeSlide {
    cursor: pointer;
    transform: scale(0.45);
    opacity: 1;
    transition: h3 5s ease-in 0.7s;
    pointer-events: unset;
  }

  .activeSlide .desc {
    visibility: visible;
    transform: inset;
    text-align: center;
  }
  .metric {
    transform: scale(1.7);
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h3.bash-name {
    /* font-size: clamp(0.5rem, -0.875rem + 6.333333vw, 1rem); */
    text-align: center;
    font-size: 12px;
    font-weight: 600;
  }
  p.bash-description {
    /* font-size: clamp(0.5rem, -0.875rem + 6.333333vw, 1rem); */
    text-align: center;
    height: 70vh;
    font-size: 9px;
    /* width: 90vw; */
  }
  p.click-notify {
    text-align: center;
    font-size: 8px;
  }
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.logo {
  margin-bottom: 1rem;
}
.icts {
  width: 30%;
  margin-top: 1rem;
}

.names {
  font-size: 18px;
  text-align: center;
}

