.logo {
  transform: scale(0.5);
}

.anim {
  opacity: 0.7;
  transition: 0.15s ease-in-out;
}
.anim:hover {
  opacity: 1;
}

.navs {
  font-weight: 500;
  font-size: 17px;
  margin-right: 1rem;
  transition: 0.2s ease-in-out;
}
.navs:hover {
  font-weight: 600;
}
.loader-div {
  transition: 0.1s ease-in-out;
}
